import React from 'react';
import styled from 'styled-components';

import Layout from '../components/layout';

const CalendarContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 30px;
  iframe {
    border-width: 0;
    min-width: 350px;
    width: 90%;
  }

  @media (min-width: ${(props) => props.theme.breakpoint}) {
    iframe {
      min-width: 700px;
    }
  }

  @media (min-width: 1000px) {
    iframe {
      min-width: 1000px;
    }
  }
`;
export default function Calendar() {
  return (
    <Layout>
      <CalendarContainer>
        <iframe
          src="https://www.google.com/calendar/embed?height=600&amp;wkst=1&amp;bgcolor=%23FFFFFF&amp;src=d3of1p6afr31ja7a0epkrug4og%40group.calendar.google.com&amp;color=%2329527A&amp;ctz=America%2FNew_York"
          height="700"
          frameBorder="0"
          scrolling="no"
          title="lodge-calendar"
        />
      </CalendarContainer>
    </Layout>
  );
}
